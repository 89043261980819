import React from "react";
import { useRef, useMemo, useState, useEffect } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import { useFormik } from "formik";
import { Field } from "formik";
import DatePicker from "react-datepicker";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import * as Yup from "yup";
import img from "../../../logo.svg";
import { useDispatch, useSelector } from "react-redux";
import { fetchBrandList, fetchModelList } from "../BrandAndModel/brandSlice";
import { fetchSingleProduct } from "../Product/productSlice";
import CreatableSelect from "react-select/creatable";
import LoadingSpinner from "../Loader/LoadingSpinner";
// import { set } from "immer/dist/internal";

function ProductEdit() {
  const [allDropDown, setAllDropDown] = useState([]);
  const location = useLocation();
  const state = location.state;
  const [modelId, setModelId] = useState();
  const [brandName, setBrandName] = useState();
  const [other, setOther] = useState(false);
  const [date, setDate] = useState();
  const [notCertain, setNotCertain] = useState();
  const [factoryGem, setFactoryGem] = useState(false);
  const [customGemSet, setCustomGemSet] = useState(false);
  const [images, setImages] = useState([]);
  const [imagesPrimary, setImagesPrimary] = useState();
  const [imageSecondaryTarget, setImageSecondaryTarget] = useState([]);
  const autoCompleteRef = useRef();
  const inputRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [isSelectLoading, setIsSelectLoading] = useState(false);
  const [newBrand, setNewBrand] = useState();
  const [defaultImages, setDefaultImages] = useState([]);
  const [imagesIf, setImagesIfImg] = useState([]);
  const [sendDefaultImages, setSendDefaultImages] = useState([]);
  const [newCreatedModel, setNewCreatedModel] = useState(false);
  const [defaultBrand, setDefaultBrand] = useState({ value: "", label: "" });
  const [defaultModel, setDefaultModel] = useState({ value: "", label: "" });
  const [defaultModelOnChange, setdefaultModelOnChange] = useState({
    value: "",
    label: "",
    change: false,
  });
  const [defaultNoCertain, setDefaultNoCertain] = useState();
  const [defaultDial, setDefaultDial] = useState({ value: "", label: "" });
  const [defaultMovement, setDefaultMovement] = useState({
    value: "",
    label: "",
  });
  const [defaultCaseMaterial, setDefaultCaseMaterial] = useState({
    value: "",
    label: "",
  });
  const [defaultBracelet, setDefaultBracelet] = useState({
    value: "",
    label: "",
  });
  const [defaultClasp, setDefaultClasp] = useState({ value: "", label: "" });
  const [defaultDialMarker, setDefaultDialMarker] = useState({
    value: "",
    label: "",
  });
  const [defaultCaseSize, setDefaultCaseSize] = useState({
    value: "",
    label: "",
  });
  const [defaultAccessories, setDefaultAccessories] = useState({
    value: "",
    label: "",
  });
  const [defaultTitle, setDefaultTitle] = useState();
  const [defaultPrice, setDefaultPrice] = useState("");
  const [defaultDescription, setDefaultDescription] = useState();
  const [defaultWatchCondition, setDefaultWatchCondition] = useState();
  const [defaultGender, setDefaultGender] = useState();
  const [defaultFactoryGem, setDefaultFactoryGem] = useState();
  const [defaultFactoryDial, setDefaultFactoryDial] = useState();
  const [defaultFactoryBracelet, setDefaultFactoryBracelet] = useState();
  const [defaultFactoryBezel, setDefaultFactoryBezel] = useState();
  const [defaultFactoryLugs, setDefaultFactoryLugs] = useState();
  const [defaultFactoryClasp, setDefaultFactoryClasp] = useState();
  const [defaultCustomGemDial, setDefaultCustomGemDial] = useState();
  const [ustomCaseToggle, setCustomCaseToggel] = useState();
  const [defaultCustomGemCase, setDefaultCustomGemCase] = useState();
  const [defaultCustomGemBracelet, setDefaultCustomGemBracelet] = useState();
  const [defaultCustomGemBezel, setDefaultCustomGemBezel] = useState();
  const [defaultCustomGemLugs, setDefaultCustomGemLugs] = useState();
  const [defaultCustomGemClasp, setDefaultCustomGemClasp] = useState();
  const [defaultCustomGem, setDefaultCustomGem] = useState();
  const [defaultLocation, setDefaultLocation] = useState();
  const [defaultLatitude, setDefaultLatitude] = useState();
  const [defaultLongitude, setDefaultLongitude] = useState();
  const [defaultFactoryDialCheck, setDefaultFactoryDialCheck] = useState();
  const [thumbnailUrl, setThumbnailUrl] = useState(null);
  const [defaultThumbnailUrl, setdefaultThumbnailUrl] = useState(null);
  const [defaultThumbnailUrlSecondary, setdefaultThumbnailUrlSecondary] =
    useState([]);
  const [defaultFactoryDialToggle, setDefaultFactoryDialToggle] = useState();
  const [defaultFactoryBraceletToggle, setDefaultFactoryBraceletToggle] =
    useState();
  const [defaultFactoryBezelToggle, setDefaultFactoryBezelToggle] = useState();
  const [defaultFactoryLugsToggle, setDefaultFactoryLugsToggle] = useState();
  const [defaultFactoryClaspToggle, setDefaultFactoryClaspToggle] = useState();
  const [defaultCustomCaseToggle, setDefaultCustomCaseToggle] = useState();
  const [defaultCustomDialToggle, setDefaultCustomDialToggle] = useState();
  const [defaultCustomBraceletToggle, setDefaultCustomBraceletToggle] =
    useState();
  const [defaultCustomBezelToggle, setDefaultCustomBezelToggle] = useState();
  const [defaultCustomLugsToggle, setDefaultCustomLugsToggle] = useState();
  const [defaultCustomClaspToggle, setDefaultCustomClaspToggle] = useState();
  const [videoUrl, setVideoUrl] = useState(null);
  const videoRef = useRef(null);
  const videoRefDefault = useRef(null);
  const videoSecondaryRef = useRef([]);
  const canvasRef = useRef(null);
  const canvasRefDefault = useRef(null);
  const canvasSecondaryRef = useRef([]);

  console.log("state", state);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const accessories = []; //accessories
  const dialOption = []; //DIAL
  const dialMarOption = []; //DIALMARKERS
  const caseSizeOption = []; //CASESIZE
  const movementOption = []; //MOVEMENT
  const caseMaterialOption = []; //CASEMATERIAL
  const strapOption = []; //STRAPBRACELET
  const claspOption = []; //CLASP

  const options = [];
  const modelOptions = [];
  const product = useSelector((state) => state.product.product);
  const dataLoading = useSelector((state) => state.product.isLoading);

  useEffect(() => {
    dispatch(fetchSingleProduct(state));
    dispatch(fetchBrandList());
    
  }, [dispatch, state]);

  useEffect(() => {
    if (modelId) {
      dispatch(fetchModelList(modelId));
      getAllDropDownList(modelId);



    }
  }, [modelId]);

  const generateDefaultThumbnail = () => {
    const video = videoRefDefault.current;
    const canvas = canvasRefDefault.current;
    const context = canvas.getContext("2d");

    video.onloadeddata = () => {
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      const thumbnailDataUrl = canvas.toDataURL();
      console.log("thumbnailDataUrl line 237", thumbnailDataUrl);
      setdefaultThumbnailUrl(thumbnailDataUrl);
      // video.currentTime = 2;
      // video.onseeked = () => {

      // }
    };
    if (videoUrl) {
      video.src = videoUrl;
    }
  };
  useEffect(() => {
    if (videoUrl && !dataLoading) {
      generateDefaultThumbnail();
    }
  }, [videoUrl]);

  const brands = useSelector((state) => state.brand.brandsList);
  if (brands) {
    brands.data?.forEach((element) => {
      options.push({ value: element.id, label: element.name });
    });
    let length = options.length - 1;
    let lastElement = options[length];
    options.push({ value: lastElement?.value + 1, label: "Other" });
  }

  const models = useSelector((state) => state.brand.modelList);
  if (models) {
    models.data?.forEach((element) => {
      modelOptions.push({ value: element.id, label: element.name });
    });
    console.log("modelOptions", modelOptions);
  }

  const optionss = {
    fields: ["address_components", "geometry", "name"],
    types: ["establishment"],
  };
  /** 
  useEffect(() => {
    setTimeout(() => {
      autoCompleteRef.current = new window.google.maps.places.Autocomplete(
        inputRef.current,
        optionss
      );
      autoCompleteRef.current.addListener("place_changed", async function () {
        const place = await autoCompleteRef.current.getPlace();
        formik.setFieldValue("location", place.name);
        formik.setFieldValue("latitude", place?.geometry?.location?.lat());
        formik.setFieldValue("longitude", place?.geometry?.location?.lng());
      });
    }, 1000);
  }, [product, allDropDown]);
  */
  console.log("product", product);


  const getAllDropDownList = (modelId) => {
    axios({
      url: process.env.REACT_APP_API_KEY + "get-product-dropdown/"+modelId,
      method: "get",
      params: {},
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => {
        console.log("response==>", response.data.data);
        setAllDropDown(response.data.data);
      })
      .catch((error) => {
        console.log("error==>", error);
      });
  };

  if (allDropDown) {
    allDropDown.ACCESSORIES?.forEach((element) => {
      accessories.push({ value: element.id, label: element.name });
    });
    allDropDown.DIAL?.forEach((element) => {
      dialOption.push({ value: element.id, label: element.name });
    });
    allDropDown.DIALMARKERS?.forEach((element) => {
      dialMarOption.push({ value: element.id, label: element.name });
    });
    allDropDown.CASESIZE?.forEach((element) => {
      caseSizeOption.push({ value: element.id, label: element.name });
    });
    allDropDown.MOVEMENT?.forEach((element) => {
      movementOption.push({ value: element.id, label: element.name });
    });
    allDropDown.CASEMATERIAL?.forEach((element) => {
      caseMaterialOption.push({ value: element.id, label: element.name });
    });
    allDropDown.STRAPBRACELET?.forEach((element) => {
      strapOption.push({ value: element.id, label: element.name });
    });
    allDropDown.CLASP?.forEach((element) => {
      claspOption.push({ value: element.id, label: element.name });
    });
  }

  const generateThumbnail = () => {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    context.drawImage(video, 0, 0, canvas.width, canvas.height);
    const thumbnailDataUrl = canvas.toDataURL();
    setThumbnailUrl(thumbnailDataUrl);
  };

  const generateSecondaryThumbnail = (canvas, i) => {
    const video = videoSecondaryRef.current;
    const context = canvas.getContext("2d");

    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    context.drawImage(video, 0, 0, canvas.width, canvas.height);
    const thumbnailDataUrl = canvas.toDataURL();
    // return thumbnailDataUrl;
    setImages((oldArr) => {
      const newArr = [...oldArr];
      // console.log('222', i,oldArr)
      newArr[i][0]["thumbnail"] = thumbnailDataUrl;
      return newArr;
    });
  };

  // const generateThumbnails = (videoUrls) => {
  //     console.log('here thumbnail', videoUrls);

  //     for (let i = 0; i < videoUrls.length; i++) {
  //         console.log("videoUrl", videoUrls[i])

  //         if (videoUrls[i].type.toLowerCase() === 'video') {
  //             const videoUrl = videoUrls[i].file;
  //             const video = videoSecondaryRef.current[i];
  //             const canvas = canvasSecondaryRef.current[i];
  //             video.onloadeddata = () => {
  //                 canvas.width = video.videoWidth;
  //                 canvas.height = video.videoHeight;

  //                 const context = canvas.getContext('2d');
  //                 context.drawImage(video, 0, 0, canvas.width, canvas.height);
  //                 const thumbnailDataUrl = canvas.toDataURL();

  //                 // newThumbnails[i] = thumbnailDataUrl;
  //                 setdefaultThumbnailUrlSecondary([...defaultThumbnailUrlSecondary, thumbnailDataUrl]);
  //                 // video.current = 5;
  //                 // video.onseeked = () => {

  //                 // }

  //             };

  //             if (videoUrl) {
  //                 video.src = videoUrl;
  //             }
  //         }
  //         // else if (videoUrls[i].type.toLowerCase() === 'image') {
  //         //     setdefaultThumbnailUrlSecondary([...defaultThumbnailUrlSecondary, videoUrls[i].file])
  //         // }
  //     }
  // };
  useEffect(() => {
    console.log("thumbnailurlsec", defaultThumbnailUrlSecondary);
  }, [defaultThumbnailUrlSecondary]);

  const handleVideoRefs = (index) => (ref) => {
    videoSecondaryRef.current[index] = ref;
  };

  const handleCanvasRefs = (index) => (ref) => {
    canvasSecondaryRef.current[index] = ref;
  };

  const Images = ({ images, setImages }) => {
    return (
      <>
        {product?.productData?.data?.files.length !== 0
          ? product?.productData?.data?.files.map((element, i) => (
              <>
                {element.type.toLowerCase() === "video" && (
                  <>
                    {/* <img src={defaultThumbnailUrlSecondary} alt={`Thumbnail ${i + 1}`} /> */}
                    <video
                      ref={handleVideoRefs(i)}
                      style={{ display: "none" }}
                      controls
                    >
                      <source src={element.file} type="video/mp4" />
                    </video>
                    <canvas
                      ref={handleCanvasRefs(i)}
                      style={{ display: "none" }}
                    ></canvas>
                  </>
                )}
              </>
            ))
          : ""}
        <div className="img_collection">
          {defaultImages?.length !== 0
            ? defaultImages?.map((element, i) => (
                <>
                  {element.type.toLowerCase() === "image" && (
                    <div key={i} className="collection_gallery">
                      <img src={element.file} alt="" />
                      <span
                        onClick={(event) => {
                          setDefaultImages(
                            defaultImages.filter((el, index) => {
                              return index !== i;
                            })
                          );
                          setSendDefaultImages(
                            defaultImages.filter((el, index) => {
                              return index === i;
                            })
                          );
                        }}
                        className="kill-btn"
                      >
                        X
                      </span>
                    </div>
                  )}

                  {element.type.toLowerCase() === "video" && (
                    <div key={i} className="collection_gallery">
                      <span className="material-icons movie" title="Movie">
                        movie
                      </span>
                      <span
                        onClick={(event) => {
                          setDefaultImages(
                            defaultImages.filter((el, index) => {
                              return index !== i;
                            })
                          );
                          setSendDefaultImages(
                            defaultImages.filter((el, index) => {
                              return index === i;
                            })
                          );
                        }}
                        className="kill-btn"
                      >
                        X
                      </span>
                    </div>
                  )}
                </>
              ))
            : ""}
          {images.map((el, i) => {
            console.log("logger", el);
            if (el[0].type === "video") {
              return (
                <>
                  <div key={i} className="collection_gallery">
                    <span className="material-icons movie" title="Movie">
                      movie
                    </span>
                    <span
                      onClick={(event) => {
                        setImages(
                          images.filter((el, index) => {
                            return index !== i;
                          })
                        );
                        setImageSecondaryTarget(
                          imageSecondaryTarget.filter((el, index) => {
                            return index !== i;
                          })
                        );
                      }}
                      className="kill-btn"
                    >
                      X
                    </span>
                  </div>
                </>
              );
            }

            return (
              <div key={i} className="collection_gallery">
                <img src={el[0].url} alt="" />
                <span
                  onClick={(event) => {
                    setImages(
                      images.filter((el, index) => {
                        return index !== i;
                      })
                    );
                    setImageSecondaryTarget(
                      imageSecondaryTarget.filter((el, index) => {
                        return index !== i;
                      })
                    );
                  }}
                  className="kill-btn"
                >
                  X
                </span>
              </div>
            );
          })}
          <div className="upload-btn-wrapper">
            <button className="btn">+</button>
            <input
              type="file"
              onChange={(event) => {
                setdefaultThumbnailUrlSecondary([]);
                // setImages([])
                const selectedImages = [];
                const targetImages = event.target.files;
                const targetImagesObject = [...targetImages];
                setImageSecondaryTarget([
                  ...imageSecondaryTarget,
                  targetImagesObject,
                ]);

                targetImagesObject.map((file, i) => {
                  if (file.type === "video/mp4" || file.type === "video/ogg") {
                    // console.log(images.length);
                    const video = videoSecondaryRef.current;
                    const canvas = canvasSecondaryRef.current;

                    video.src = URL.createObjectURL(file);
                    video.onloadedmetadata = () => {
                      video.currentTime = 5; // Capture the frame at 5 seconds
                      video.onseeked = () =>
                        generateSecondaryThumbnail(canvas, images.length);
                    };
                    return selectedImages.push({
                      url: URL.createObjectURL(file),
                      type: "video",
                    });
                  }
                  return selectedImages.push({
                    index: i,
                    url: URL.createObjectURL(file),
                    type: "image",
                  });
                });
                setImages([...images, selectedImages]);
              }}
              name="myfile"
            />
          </div>
        </div>
      </>
    );
  };

  const htmlTagValidator = (value) => {
    const regex = /<(\"[^\"]*\"|'[^']*'|[^'\">])*>/;
    return regex.test(value) ? undefined : "Invalid HTML tag";
    // return undefined;
  };

  const validationSchema = Yup.object().shape({
    brand_id: Yup.string().required("Brand is required"),
    dated: Yup.string().required("Dated is required"),
    model_id: Yup.string().required("Model is required"),
    title: Yup.string()
      .required("Title is required")
      .min(3, "Minimum 3 Character")
      .max(100, "Name must not exceed 100 characters")
      .test("htmlTag", "Invalid Input Format", htmlTagValidator),
    watch_condition: Yup.string().required("Watch Condition is required"),
    price: Yup.number()
      .positive("Postive Values Only")
      .required("Price is Required"),
    gender_type: Yup.string().required("Gender is required"),
    accessories: Yup.string().required("Accessories is required"),
    description: Yup.string().test(
      "tags",
      "Invalid Input, No Tags allowed",
      (value, ctx) => {
        const regex = /<(\"[^\"]*\"|'[^']*'|[^'\">])*>/;
        if (regex.test(value)) {
          return false;
        } else {
          return true;
        }
      }
    ),
    factory_gem_set: Yup.string().required(
      "Factory Checkbox needs to be selected"
    ),
    factory_gem: Yup.mixed().when("factory_gem_set", {
      is: (val) => val?.toLowerCase() === "yes",
      then: () =>
        Yup.object().test({
          test: function (value, ctx) {
            const regex = /<(\"[^\"]*\"|'[^']*'|[^'\">])*>/;
            const { dial, bracelet, bezel, lugs, clasp } = value;
            console.log("test dial", regex.test(dial));

            if (
              regex.test(dial) ||
              regex.test(bracelet) ||
              regex.test(bezel) ||
              regex.test(lugs) ||
              regex.test(clasp)
            ) {
              return ctx.createError({
                message: "Invalid Format, No  Tags allowed",
              });
            }
            if (
              !(
                dial !== undefined ||
                bracelet !== undefined ||
                bezel !== undefined ||
                lugs !== undefined ||
                clasp !== undefined
              )
            ) {
              return ctx.createError({
                message:
                  "'At least one of the values from dial, bracelet, bezel, lugs, clasp is required",
              });
            }
            return true;
          },
          // message: 'At least one of the values from case, dial, bracelet, bezel, lugs, clasp is required and must be of a valid format',
        }),
    }),
    custom_gem_set: Yup.string().required(
      "Custom CheckBox needs to be selected"
    ),
    custom_gem: Yup.object().when("custom_gem_set", {
      is: (val) => val?.toLowerCase() === "yes",
      then: () =>
        Yup.object().test({
          test: function (value, ctx) {
            const { dial, bracelet, bezel, lugs, clasp } = value;
            console.log("value", value);
            const regex = /<(\"[^\"]*\"|'[^']*'|[^'\">])*>/;
            if (
              regex.test(value?.case) ||
              regex.test(dial) ||
              regex.test(bracelet) ||
              regex.test(bezel) ||
              regex.test(lugs) ||
              regex.test(clasp)
            ) {
              return ctx.createError({
                message: "Invalid Format, No  Tags allowed",
              });
            }
            if (
              !(
                value?.case !== undefined ||
                dial !== undefined ||
                bracelet !== undefined ||
                bezel !== undefined ||
                lugs !== undefined ||
                clasp !== undefined
              )
            ) {
              return ctx.createError({
                message:
                  "'At least one of the values from dial, bracelet, bezel, lugs, clasp is required",
              });
            }
            return true;
          },
        }),
    }),
    thumb_image: Yup.mixed()
      .test("fileType", "Only Video, and Image files are allowed", (value) => {
        if (!value) return true; // Allow empty value
        const supportedFormats = [
          "video/mp4",
          "video/ogg",
          "video/avi",
          "image/jpeg",
          "image/png",
          "image/jpg",
        ];
        return supportedFormats.includes(value.type);
      })
      .test("fileSize", "Maximum file size exceeded", (value) => {
        if (!value) return true; // Allow empty value
        const maxSize = value.type.includes("video")
          ? 5 * 1024 * 1024
          : 2 * 1024 * 1024;
        return value.size <= maxSize;
      }),
  });

  const userId = localStorage.getItem("userID");
  const userRole = localStorage.getItem("user_role");

  const formik = useFormik({
    initialValues: {
      title: defaultTitle,
      user_id: userId,
      newBrand: false,
      newModel: false,
      brand_id: defaultBrand?.value,
      model_id: defaultModel?.value,
      price: defaultPrice,
      watch_condition: defaultWatchCondition,
      dated: moment(date).format("yyyy-MM-DD"),
      no_certain: defaultNoCertain,
      accessories: defaultAccessories.value,
      description: defaultDescription,
      gender_type: defaultGender,
      dial: defaultDial.value,
      dial_markers: defaultDialMarker.value,
      case_size: defaultCaseSize.value,
      movement: defaultMovement.value,
      case_materials: defaultCaseMaterial.value,
      bracelet: defaultBracelet.value,
      clasp: defaultClasp.value,
      factory_gem_set: defaultFactoryGem,
      factory_gem: {
        dial: defaultFactoryDial,
        bracelet: defaultFactoryBracelet,
        bezel: defaultFactoryBezel,
        lugs: defaultFactoryLugs,
        clasp: defaultFactoryClasp,
      },
      custom_gem: {
        case: defaultCustomGemCase,
        dial: defaultCustomGemDial,
        bracelet: defaultCustomGemBracelet,
        bezel: defaultCustomGemBezel,
        lugs: defaultCustomGemLugs,
        clasp: defaultCustomGemClasp,
      },
      custom_gem_set: defaultCustomGem,
      location: defaultLocation,
      longitude: defaultLongitude,
      latitude: defaultLatitude,
      thumb_image: "",
      product_file: "",
      user_role: userRole,
    },
    enableReinitialize: true,
    validationSchema,
    validateOnChange: true,
    // validateOnBlur: false,
    onSubmit: (data) => {
      setIsLoading(true);
      console.log("data", data);
      let newBrandStatus;
      if (brands) {
        brands.data.find((el, i) => el.id === data.brand_id)
          ? (newBrandStatus = false)
          : (newBrandStatus = true);
        if (newBrandStatus) {
          console.log("newBrand");
          data.newBrand = true;
          data.newModel = true;
        } else {
          data.newBrand = false;
          data.newBrand = false;
        }
      }

      let newModelStatus;
      if (models.data.length > 0) {
        console.log("models 635", models.data);
        models.data.find((el, i) => el.id === data.model_id)
          ? (newModelStatus = false)
          : (newModelStatus = true);
        if (newModelStatus) {
          data.newModel = true;
        } else {
          data.newModel = false;
        }
      }
      if (data.factory_gem_set === "no") {
        data.factory_gem.dial = "";
        data.factory_gem.bracelet = "";
        data.factory_gem.clasp = "";
        data.factory_gem.lugs = "";
        data.factory_gem.bezel = "";
      }
      if (data.custom_gem_set === "no") {
        data.custom_gem.case = "";
        data.custom_gem.dial = "";
        data.custom_gem.bracelet = "";
        data.custom_gem.clasp = "";
        data.custom_gem.lugs = "";
        data.custom_gem.bezel = "";
      }
      // if (data.no_certain === 'yes') {
      //     data.dated = null;
      // }
      console.log("updated", data);

      axios
        .post(
          process.env.REACT_APP_API_KEY + `update-product/${state.id}`,
          data,
          {
            headers: {
              "Content-type": "multipart/form-data",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            // params: {
            //     type: "brand",
            // },
          }
        )
        .then((res) => {
          if (res?.status === 200) {
            swal({
              title: "Success!",
              text: "Updated Successfully!",
              icon: "success",
              button: "Okay",
            });
            setIsLoading(false);

            // eslint-disable-next-line no-lone-blocks
            {
              localStorage.getItem("user_role") === "super_admin"
                ? navigate("/product-management")
                : navigate("/product-management");
            }
          }
        })
        .catch((error) => {
          if (error.response?.status === 422) {
            swal("Oops", error.response.data.message, "error");
          }
          setIsLoading(false);
        });
    },
  });
  /// ######55555 allDropDown
  useEffect(() => {
    console.log(">>s>>>", product?.productData?.data);
    setModelId(product?.productData?.data?.brand.id);
    setDefaultBrand({
      value: product?.productData?.data?.brand.id,
      label: product?.productData?.data?.brand.name,
    });
    setDefaultModel({
      value: product?.productData?.data?.model.id,
      label: product?.productData?.data.model.name,
    });
    setNewCreatedModel({
      value: product?.productData?.data?.model.id,
      label: product?.productData?.data.model.name,
    });

    setDefaultTitle(product?.productData?.data?.title);
    product?.productData?.data?.price
      ? setDefaultPrice(product?.productData?.data?.price)
      : setDefaultPrice("");
    if (product?.productData?.data?.dated) {
      const defaultDate = moment(product?.productData?.data?.dated).toDate();
      setDate(defaultDate);
    }

    product?.productData?.data?.watch_condition
      ? setDefaultWatchCondition(product?.productData?.data?.watch_condition)
      : setDefaultWatchCondition("");
    setNotCertain(product?.productData?.data?.no_certain === "yes");
    console.log(
      "Not Certain is line 153 ",
      product?.productData?.data?.no_certain
    );
    // product?.productData?.data?.no_certain === "yes"
    product?.productData?.data?.description
      ? setDefaultDescription(product?.productData?.data?.description)
      : setDefaultDescription("");
    product?.productData?.data?.gender_type
      ? setDefaultGender(product?.productData?.data?.gender_type)
      : setDefaultGender("");
    setDefaultFactoryGem(
      product?.productData?.data?.factory_gem_set.toLowerCase()
    );
    setDefaultCustomGem(
      product?.productData?.data?.custom_gem_set.toLowerCase()
    );
    
    
    setDefaultDial({ value: product?.productData?.data?.dial, label: product?.productData?.data?.dial_name });
    setDefaultDialMarker({
      value: product?.productData?.data?.dial_markers,
      label: product?.productData?.data?.dial_mar_name,
    });
    setDefaultCaseSize({
      value: product?.productData?.data?.case_size,
      label: product?.productData?.data?.casesize_name,
    });
    setDefaultMovement({
      value: product?.productData?.data?.movement,
      label: product?.productData?.data?.movem_name,
    });
    setDefaultCaseMaterial({
      value: product?.productData?.data?.case_materials,
      label: product?.productData?.data?.case_ma_name,
    });
    setDefaultBracelet({
      value: product?.productData?.data?.bracelet,
      label: product?.productData?.data?.bracelet_acc_name,
    });
    setDefaultClasp({
      value: product?.productData?.data?.clasp,
      label: product?.productData?.data?.clasp_acc_name,
    });

    setDefaultAccessories({
      value: product?.productData?.data?.accessories?.id,
      label: product?.productData?.data?.accessories?.name,
    });
    

    

    if (product?.productData?.data?.thumb_image) {
      let urlFromFrontEnd = product?.productData?.data?.thumb_image;
      let pathname = new URL(urlFromFrontEnd).pathname;
      let asset = pathname.split("/").pop();
      console.log(asset);
      if (["png", "jpg", "jpeg"].includes(asset.split(".").pop())) {
        setImagesIfImg(product?.productData?.data?.thumb_image);
      } else {
        setVideoUrl(product?.productData?.data?.thumb_image);
      }
    }
    // if (product?.productData?.data?.files.length > 0) {
    //     generateThumbnails(product?.productData?.data?.files);
    // }
    let dialFactory = product?.productData?.data?.gem_set.find(
      (el) => el.gem_position === "dial" && el.type === "factory"
    );
    if (dialFactory?.value) {
      setDefaultFactoryDial(dialFactory?.value);
      setDefaultFactoryDialToggle(!!dialFactory?.value);
    } else {
      setDefaultFactoryDial("");
      setDefaultFactoryDialToggle(false);
    }
    let braceletFactory = product?.productData?.data?.gem_set.find(
      (el) => el.gem_position === "bracelet" && el.type === "factory"
    );
    if (braceletFactory?.value) {
      setDefaultFactoryBracelet(braceletFactory?.value);
      setDefaultFactoryBraceletToggle(!!braceletFactory?.value);
    } else {
      setDefaultFactoryBracelet("");
      setDefaultFactoryBraceletToggle(false);
    }
    let bezelFactory = product?.productData?.data?.gem_set.find(
      (el) => el.gem_position === "bezel" && el.type === "factory"
    );
    if (bezelFactory?.value) {
      setDefaultFactoryBezel(bezelFactory?.value);
      setDefaultFactoryBezelToggle(!!bezelFactory?.value);
    } else {
      setDefaultFactoryBezel("");
      setDefaultFactoryBezelToggle(false);
    }
    let lugsFactory = product?.productData?.data?.gem_set.find(
      (el) => el.gem_position === "lugs" && el.type === "factory"
    );
    if (lugsFactory?.value) {
      setDefaultFactoryLugs(lugsFactory?.value);
      setDefaultFactoryLugsToggle(!!lugsFactory?.value);
    } else {
      setDefaultFactoryLugs("");
      setDefaultFactoryLugsToggle(false);
    }
    let claspFactory = product?.productData?.data?.gem_set.find(
      (el) => el.gem_position === "clasp" && el.type === "factory"
    );
    if (claspFactory?.value) {
      setDefaultFactoryClasp(claspFactory?.value);
      setDefaultFactoryClaspToggle(!!claspFactory?.value);
    } else {
      setDefaultFactoryClasp("");
      setDefaultFactoryClaspToggle(false);
    }
    let caseCustom = product?.productData?.data?.gem_set.find(
      (el) => el.gem_position === "case" && el.type === "custom"
    );
    if (caseCustom?.value) {
      setDefaultCustomGemCase(caseCustom?.value);
      setDefaultCustomCaseToggle(!!caseCustom?.value);
    } else {
      setDefaultCustomGemCase("");
      setDefaultCustomCaseToggle(false);
    }
    let dialCustom = product?.productData?.data?.gem_set.find(
      (el) => el.gem_position === "dial" && el.type === "custom"
    );
    if (dialCustom?.value) {
      setDefaultCustomGemDial(dialCustom?.value);
      setDefaultCustomDialToggle(!!dialCustom?.value);
    } else {
      setDefaultCustomGemDial("");
      setDefaultCustomDialToggle(false);
    }
    let braceletCustom = product?.productData?.data?.gem_set.find(
      (el) => el.gem_position === "bracelet" && el.type === "custom"
    );
    if (braceletCustom?.value) {
      setDefaultCustomGemBracelet(braceletCustom?.value);
      setDefaultCustomBraceletToggle(!!braceletCustom?.value);
    } else {
      setDefaultCustomGemBracelet("");
      setDefaultCustomBraceletToggle(false);
    }
    let bezelCustom = product?.productData?.data?.gem_set.find(
      (el) => el.gem_position === "bezel" && el.type === "custom"
    );
    if (bezelCustom?.value) {
      setDefaultCustomGemBezel(bezelCustom?.value);
      setDefaultCustomBraceletToggle(!!bezelCustom?.value);
    } else {
      setDefaultCustomGemBezel("");
      setDefaultCustomBraceletToggle(false);
    }
    let lugsCustom = product?.productData?.data?.gem_set.find(
      (el) => el.gem_position === "lugs" && el.type === "custom"
    );
    if (lugsCustom?.value) {
      setDefaultCustomGemLugs(lugsCustom?.value);
      setDefaultCustomLugsToggle(!!lugsCustom?.value);
    } else {
      setDefaultCustomGemLugs("");
      setDefaultCustomLugsToggle(false);
    }
    let claspCustom = product?.productData?.data?.gem_set.find(
      (el) => el.gem_position === "clasp" && el.type === "custom"
    );
    if (claspCustom?.value) {
      setDefaultCustomGemClasp(claspCustom?.value);
      setDefaultCustomClaspToggle(!!claspCustom?.value);
    } else {
      setDefaultCustomGemClasp("");
      setDefaultCustomClaspToggle(false);
    }

    setFactoryGem(
      product?.productData?.data?.factory_gem_set.toLowerCase() === "yes"
    );
    setCustomGemSet(
      product?.productData?.data?.custom_gem_set.toLowerCase() === "yes"
    );
    setDefaultLocation(product?.productData?.data?.location);
    setDefaultLatitude(product?.productData?.data?.latitude);
    setDefaultLongitude(product?.productData?.data?.longitude);
    setDefaultNoCertain(product?.productData?.data?.no_certain);
    setDefaultImages(product?.productData?.data?.files);
    // setSendDefaultImages(product?.productData?.data?.files);
  }, [product]); //allDropDown

  useEffect(() => {
    console.log("formik 123", formik.errors);
  }, [formik]);

  if (dataLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <div className="row">
        <div className="col">
          <div className="page-description">
            <h1>Edit Product</h1>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <div className="example-container">
            <div className="example-content">
              <form className="row g-3" onSubmit={formik.handleSubmit}>
                <div className="col-md-6">
                  <label htmlFor="inputTitle" className="form-label">
                    Choose Brand{" "}
                    <span style={{ color: "red", fontSize: 16 }}>*</span>
                  </label>

                  {defaultBrand?.value && (
                    <div>
                    <CreatableSelect
                      name="brand_id"
                      options={options}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isSearchable={true}
                      noOptionsMessage={() => "No Record(s) Found"}
                      isClearable
                      isDisabled={isSelectLoading}
                      isLoading={isSelectLoading}
                      defaultValue={defaultBrand}
                      onChange={(selected) => {
                        formik.setFieldValue("brand_id", selected?.value);
                        setNewBrand(selected);
                        setModelId(selected?.value);
                        setBrandName(selected?.label);
                        setNewCreatedModel(null);

                        setDefaultDial({ value: '', label: '' });
                        setDefaultDialMarker({ value: '', label: '' });
                        setDefaultCaseSize({ value: '', label: '' });
                        setDefaultMovement({ value: '', label: '' });
                        setDefaultCaseMaterial({ value: '', label: '' });
                        setDefaultBracelet({ value: '', label: '' });
                        setDefaultClasp({ value: '', label: '' });
                        
                      }}
                      onCreateOption={(inputValue) => {
                        setIsSelectLoading(true);
                        setTimeout(() => {
                          const newOption = {
                            value: inputValue,
                            label: inputValue,
                          };
                          setIsSelectLoading(false);
                          options.push(newOption);
                          setNewBrand(newOption);
                          setNewCreatedModel(null);
                          formik.setFieldValue("brand_id", newOption.value);
                        }, 1000);
                      }}
                      value={newBrand}
                    />
                    </div>
                  )}

                  <div className="text-danger">
                    {formik.errors.brand_id && formik.touched.brand_id
                      ? formik.errors.brand_id
                      : null}
                  </div>
                </div>

                <div className="col-md-6">
                  <label htmlFor="modelId" className="form-label">
                    Model<span style={{ color: "red", fontSize: 16 }}>*</span>
                  </label>

                  {defaultModel?.value && (
                    <CreatableSelect
                      name="model_id"
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isSearchable={true}
                      noOptionsMessage={() => "No Record(s) Found"}
                      isClearable
                      isDisabled={isSelectLoading}
                      isLoading={isSelectLoading}
                      defaultValue={defaultModel}
                      options={modelOptions}
                      onChange={(selected) => {
                        formik.setFieldValue("model_id", selected?.value);
                        setNewCreatedModel(selected);
                      }}
                      onCreateOption={(inputValue) => {
                        setIsSelectLoading(true);
                        setTimeout(() => {
                          const newOption = {
                            value: inputValue,
                            label: inputValue,
                          };
                          setIsSelectLoading(false);
                          // setOptions((prev) => [...prev, newOption]);
                          options.push(newOption);
                          console.log("options", options);
                          setNewCreatedModel(newOption);
                          formik.setFieldValue("model_id", newOption.value);
                        }, 1000);
                      }}
                      value={newCreatedModel}
                    />
                  )}

                  <div className="text-danger">
                    {formik.errors.model_id && formik.touched.model_id
                      ? formik.errors.model_id
                      : null}
                  </div>
                </div>

                <div className="col-md-6">
                  <label htmlFor="title" className="form-label">
                    Title<span style={{ color: "red", fontSize: 16 }}>*</span>
                  </label>
                  <input
                    name="title"
                    id="title"
                    type="text"
                    className="form-control"
                    onChange={formik.handleChange}
                    value={formik.values.title}
                    autoComplete="off"
                    placeholder="Enter product title"
                  />
                  <div className="text-danger">
                    {formik.errors.title && formik.touched.title
                      ? formik.errors.title
                      : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <label htmlFor="title" className="form-label">
                    Accessories
                    <span style={{ color: "red", fontSize: 16 }}>*</span>
                  </label>
                  {defaultAccessories.value && (
                    <Select
                      name="accessories"
                      onChange={(selected) => {
                        formik.setFieldValue("accessories", selected.value);
                      }}
                      defaultValue={defaultAccessories}
                      options={accessories}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isSearchable={true}
                      noOptionsMessage={() => "No Record(s) Found"}
                    />
                  )}
                  {!defaultAccessories.value && (
                    <Select
                      name="accessories"
                      onChange={(selected) => {
                        formik.setFieldValue("accessories", selected.value);
                      }}
                      options={accessories}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isSearchable={true}
                      noOptionsMessage={() => "No Record(s) Found"}
                    />
                  )}
                  <div className="text-danger">
                    {formik.errors.accessories && formik.touched.accessories
                      ? formik.errors.accessories
                      : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <label htmlFor="price" className="form-label">
                    Price<span style={{ color: "red", fontSize: 16 }}>*</span>
                  </label>
                  <input
                    name="price"
                    id="price"
                    type="text"
                    className="form-control"
                    onChange={formik.handleChange}
                    value={formik.values.price}
                    autoComplete="off"
                    placeholder="Enter product price"
                  />
                  <div className="text-danger">
                    {formik.errors.price && formik.touched.price
                      ? formik.errors.price
                      : null}
                  </div>
                </div>
                {/* Watch Condition */}
                <div className="col-md-12">
                  <label className="form-label">
                    Watch Condition
                    <span style={{ color: "red", fontSize: 16 }}>*</span>
                  </label>
                  <div className="department">
                    <div className="form-check dis">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="watch_condition"
                        value="brand_new"
                        id="flexRadioDefault1"
                        onChange={formik.handleChange}
                        checked={formik.values.watch_condition === "brand_new"}

                        // checked={''}
                        // onClick={(e) => radioHandler(1)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexRadioDefault1"
                      >
                        Brand New
                      </label>
                    </div>

                    <div className="form-check dis">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="watch_condition"
                        value="pre_owned"
                        id="flexRadioDefault2"
                        onChange={formik.handleChange}
                        checked={formik.values.watch_condition === "pre_owned"}
                        // onClick={(e) => radioHandler(1)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexRadioDefault2"
                      >
                        Pre-Owned
                      </label>
                    </div>
                  </div>
                  <div className="text-danger">
                    {formik.errors.watch_condition &&
                    formik.touched.watch_condition
                      ? formik.errors.watch_condition
                      : null}
                  </div>
                </div>

                {/* */}
                <div className="col-md-4 no_certain">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      checked={notCertain}
                      onChange={(event) => {
                        console.log("date is ", date);

                        if (!notCertain) {
                          // formik.setFieldValue(
                          //     "dated",
                          //    null
                          // );
                          //setDate();
                          setDefaultNoCertain("yes");
                          // formik.setFieldValue(
                          //     "no_certain",
                          //     'yes'
                          // );
                          setNotCertain(true);
                        } else {
                          setDefaultNoCertain("no");
                          setNotCertain(false);
                        }
                      }}
                      id="notCertain"
                    />
                    <label class="form-check-label bg-unset1" for="notCertain">
                      Not Certain
                    </label>
                  </div>
                </div>
                <div className="col-md-8">
                  <label htmlFor="dated" className="form-label me-2">
                    Dated
                  </label>
                  <DatePicker
                    showIcon
                    name="dated"
                    selected={date}
                    onChange={(dated) => {
                      formik.setFieldValue(
                        "dated",
                        moment(dated).format("yyyy-MM-DD")
                      );
                      setDate(dated);
                    }}
                    maxDate={new Date()}
                    className="form-control"
                    autoComplete="off"
                    placeholderText="Dated"
                    dateFormat="yyyy-MM-dd"
                  />
                  {/* <span className="new-date-icon">
                                        <i class="material-icons-two-tone">calendar_today</i>
                                    </span> */}
                  <div className="text-danger">
                    {formik.errors.dated && formik.touched.dated
                      ? formik.errors.dated
                      : null}
                  </div>
                </div>
                <div className="col-md-12">
                  <label htmlFor="description" className="form-label">
                    Tell the customers about this watch
                  </label>
                  <textarea
                    name="description"
                    id="description"
                    onChange={(event) => {
                      formik.setFieldValue("description", event.target.value);
                    }}
                    value={formik.values.description}
                    className="form-control"
                  ></textarea>
                  <div className="text-danger">
                    {formik.errors.description && formik.touched.description
                      ? formik.errors.description
                      : null}
                  </div>
                </div>
                <div className="col-md-12">
                  <label htmlFor="inputTitle" className="form-label">
                    Gender<span style={{ color: "red", fontSize: 16 }}>*</span>
                  </label>
                  <div className="department">
                    <div className="form-check dis">
                      <input
                        className="form-check-input brand_collection"
                        type="radio"
                        name="gender_type"
                        value="Male"
                        id="male"
                        onChange={formik.handleChange}
                        checked={formik.values.gender_type === "Male"}
                        // defaultChecked={formik.values.dept === "sales"}
                        // onClick={(e) => radioHandler(1)}
                      />
                      <label className="form-check-label" htmlFor="male">
                        Male
                      </label>
                    </div>
                    <div className="form-check dis">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="gender_type"
                        value="Female"
                        id="female"
                        onChange={formik.handleChange}
                        checked={formik.values.gender_type === "Female"}
                        // defaultChecked={formik.values.dept === "sales"}
                        // onClick={(e) => radioHandler(1)}
                      />
                      <label className="form-check-label" htmlFor="female">
                        Female
                      </label>
                    </div>

                    <div className="form-check dis">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="gender_type"
                        value="Unisex"
                        id="unisex"
                        onChange={formik.handleChange}
                        checked={formik.values.gender_type === "Unisex"}
                        // defaultChecked={formik.values.dept === "sales"}
                        // onClick={(e) => radioHandler(1)}
                      />
                      <label className="form-check-label" htmlFor="unisex">
                        Unisex
                      </label>
                    </div>
                  </div>
                  <div className="text-danger">
                    {formik.errors.gender_type && formik.touched.gender_type
                      ? formik.errors.gender_type
                      : null}
                  </div>
                </div>

                <div className="col-md-6">
                  <label htmlFor="dial" className="form-label">
                    Dial
                    {brandName?.toLowerCase() == ("rolex" ||
                    "audemars piguet" ||
                    "patek philippe") ? (
                      <span style={{ color: "red", fontSize: 16 }}>*</span>
                    ) : (
                      ""
                    )}
                  </label>
                  {defaultDial.value && (
                    <Select
                      name="dial"
                      onChange={(selected) => {
                        formik.setFieldValue("dial", selected);
                        console.log("selected dial", selected);
                      }}
                      defaultValue={defaultDial}
                      options={dialOption}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isSearchable={true}
                      noOptionsMessage={() => "No Record(s) Found"}
                    />
                  )}
                  {!defaultDial.value && (
                    <Select
                      name="dial"
                      onChange={(selected) => {
                        formik.setFieldValue("dial", selected.value);
                      }}
                      options={dialOption}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isSearchable={true}
                      noOptionsMessage={() => "No Record(s) Found"}
                    />
                  )}
                  <div className="text-danger"></div>
                </div>
                <div className="col-md-6">
                  <label htmlFor="dialMarkers" className="form-label">
                    Dial Markers
                  </label>
                  {defaultDialMarker.value && (
                    <Select
                      name="dial_markers"
                      id="dialMarkers"
                      onChange={(selected) => {
                        formik.setFieldValue("dial_markers", selected.value);
                      }}
                      defaultValue={defaultDialMarker}
                      options={dialMarOption}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isSearchable={true}
                      noOptionsMessage={() => "No Record(s) Found"}
                    />
                  )}
                  {!defaultDialMarker.value && (
                    <Select
                      name="dialMarkers"
                      id="dialMarkers"
                      onChange={(selected) => {
                        formik.setFieldValue("dial_markers", selected.value);
                      }}
                      // defaultValue={defaultDialMarker}
                      options={dialMarOption}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isSearchable={true}
                      noOptionsMessage={() => "No Record(s) Found"}
                    />
                  )}
                  <div className="text-danger"></div>
                </div>

                <div className="col-md-6">
                  <label htmlFor="caseSize" className="form-label">
                    Case Size
                  </label>
                  {defaultCaseSize.value && (
                    <Select
                      name="case_size"
                      id="caseSize"
                      onChange={(selected) => {
                        formik.setFieldValue("case_size", selected.value);
                      }}
                      defaultValue={defaultCaseSize}
                      options={caseSizeOption}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isSearchable={true}
                      noOptionsMessage={() => "No Record(s) Found"}
                    />
                  )}
                  {!defaultCaseSize.value && (
                    <Select
                      name="caseSize"
                      id="caseSize"
                      onChange={(selected) => {
                        formik.setFieldValue("case_size", selected.value);
                      }}
                      // defaultValue={defaultCaseSize}
                      options={caseSizeOption}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isSearchable={true}
                      noOptionsMessage={() => "No Record(s) Found"}
                    />
                  )}
                  <div className="text-danger"></div>
                </div>
                <div className="col-md-6">
                  <label htmlFor="movement" className="form-label">
                    Movement
                  </label>
                  {defaultMovement.value && (
                    <Select
                      name="movement"
                      id="movement"
                      onChange={(selected) => {
                        formik.setFieldValue("movement", selected.value);
                      }}
                      defaultValue={defaultMovement}
                      options={movementOption}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isSearchable={true}
                      noOptionsMessage={() => "No Record(s) Found"}
                    />
                  )}
                  {!defaultMovement.value && (
                    <Select
                      name="movement"
                      id="movement"
                      onChange={(selected) => {
                        formik.setFieldValue("movement", selected.value);
                      }}
                      // defaultValue={defaultMovement}
                      options={movementOption}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isSearchable={true}
                      noOptionsMessage={() => "No Record(s) Found"}
                    />
                  )}
                  <div className="text-danger"></div>
                </div>

                <div className="col-md-6">
                  <label htmlFor="caseMaterial" className="form-label">
                    Case Material
                  </label>
                  {defaultCaseMaterial.value && (
                    <Select
                      name="case_materials"
                      onChange={(selected) => {
                        formik.setFieldValue("case_materials", selected.value);
                      }}
                      defaultValue={defaultCaseMaterial}
                      options={caseMaterialOption}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isSearchable={true}
                      noOptionsMessage={() => "No Record(s) Found"}
                    />
                  )}
                  {!defaultCaseMaterial.value && (
                    <Select
                      name="caseMaterial"
                      onChange={(selected) => {
                        formik.setFieldValue("case_materials", selected.value);
                      }}
                      options={caseMaterialOption}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isSearchable={true}
                      noOptionsMessage={() => "No Record(s) Found"}
                    />
                  )}
                  <div className="text-danger"></div>
                </div>
                <div className="col-md-6">
                  <label htmlFor="strapBracelet" className="form-label">
                    Strap/Bracelet
                    {brandName?.toLowerCase() == ("rolex" ||
                    "audemars piguet" ||
                    "patek philippe") ? (
                      <span style={{ color: "red", fontSize: 16 }}>*</span>
                    ) : (
                      ""
                    )}
                  </label>
                  {defaultBracelet.value && (
                    <Select
                      name="bracelet"
                      id="strapBracelet"
                      onChange={(selected) => {
                        formik.setFieldValue("bracelet", selected.value);
                      }}
                      defaultValue={defaultBracelet}
                      options={strapOption}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isSearchable={true}
                      noOptionsMessage={() => "No Record(s) Found"}
                    />
                  )}
                  {!defaultBracelet.value && (
                    <Select
                      name="strapBracelet"
                      id="strapBracelet"
                      onChange={(selected) => {
                        formik.setFieldValue("bracelet", selected.value);
                      }}
                      // defaultValue={defaultBracelet}
                      options={strapOption}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isSearchable={true}
                      noOptionsMessage={() => "No Record(s) Found"}
                    />
                  )}
                  <div className="text-danger"></div>
                </div>

                <div className="col-md-12">
                  <label htmlFor="clasp" className="form-label">
                    Clasp
                  </label>
                  {defaultClasp.value && (
                    <Select
                      name="clasp"
                      id="clasp"
                      onChange={(selected) => {
                        formik.setFieldValue("clasp", selected.value);
                      }}
                      value={defaultClasp}
                      options={claspOption}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isSearchable={true}
                      noOptionsMessage={() => "No Record(s) Found"}
                    />
                  )}
                  {!defaultClasp.value && (
                    <Select
                      name="clasp"
                      id="clasp"
                      onChange={(selected) => {
                        formik.setFieldValue("clasp", selected.value);
                      }}
                      // value={defaultClasp}
                      options={claspOption}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isSearchable={true}
                      noOptionsMessage={() => "No Record(s) Found"}
                    />
                  )}
                  <div className="text-danger"></div>
                </div>
                {/* Gem Set */}
                <div className="col-md-12">
                  <label className="form-label">Factory Gem Set?</label>
                  <div className="department">
                    <div className="form-check dis">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="factoryGem"
                        value="Yes"
                        id="yes"
                        onChange={() => {
                          setFactoryGem(true);
                          setDefaultFactoryGem("yes");
                          formik.setFieldValue("factory_gem_set", "Yes");
                        }}
                        checked={defaultFactoryGem === "yes"}
                        // defaultChecked={formik.values.dept === "sales"}
                        // onClick={(e) => radioHandler(1)}
                      />
                      <label className="form-check-label" htmlFor="yes">
                        Yes
                      </label>
                    </div>

                    <div className="form-check dis">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="factoryGem"
                        value="No"
                        id="no"
                        onChange={() => {
                          setFactoryGem(false);
                          setDefaultFactoryGem("no");

                          // setDefaultFactoryClasp("");
                          // setDefaultFactoryBezel("");
                          // setDefaultFactoryBracelet("");
                          // setDefaultFactoryDial("");
                          // setDefaultFactoryLugs("");
                        }}
                        checked={defaultFactoryGem === "no"}
                        // defaultChecked={formik.values.dept === "sales"}
                        // onClick={(e) => radioHandler(1)}
                      />
                      <label className="form-check-label" htmlFor="no">
                        No
                      </label>
                    </div>
                  </div>

                  {factoryGem ? (
                    <>
                      if yes, tick what's gem-setted
                      <div className="row checklist-row">
                        <div className="col-md-3">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value="factoryGemDial"
                              id="flexCheckDefault"
                              onChange={(event) => {
                                if (defaultFactoryDialToggle) {
                                  formik.setFieldValue("factory_gem.dial", "");
                                }
                                setDefaultFactoryDialToggle(
                                  !defaultFactoryDialToggle
                                );
                              }}
                              checked={defaultFactoryDialToggle}
                            />
                            <label
                              class="form-check-label bg-unset1"
                              for="flexCheckDefault"
                            >
                              Dial
                            </label>
                          </div>
                        </div>
                        <div className="col-md-9">
                          {defaultFactoryDialToggle && (
                            <>
                              <label>Indicate what's Gem-setted</label>
                              <input
                                type="text"
                                name="factory_gem.dial"
                                onChange={formik.handleChange}
                                value={formik.values.factory_gem.dial}
                                className="form-control"
                              />
                            </>
                          )}
                        </div>
                        <div className="col-md-3">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckDefault"
                              onChange={(event) => {
                                if (defaultFactoryBraceletToggle) {
                                  formik.setFieldValue(
                                    "factory_gem.bracelet",
                                    ""
                                  );
                                }
                                setDefaultFactoryBraceletToggle(
                                  !defaultFactoryBraceletToggle
                                );
                              }}
                              checked={defaultFactoryBraceletToggle}
                            />
                            <label
                              class="form-check-label bg-unset1"
                              for="flexCheckDefault"
                            >
                              Bracelet
                            </label>
                          </div>
                        </div>
                        <div className="col-md-9">
                          {defaultFactoryBraceletToggle && (
                            <>
                              <label>Indicate what's Gem-setted</label>
                              <input
                                type="text"
                                name="factory_gem.bracelet"
                                onChange={formik.handleChange}
                                value={formik.values.factory_gem.bracelet}
                                className="form-control"
                              />
                            </>
                          )}
                        </div>
                        <div className="col-md-3">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckDefault"
                              onChange={(event) => {
                                if (defaultFactoryBezelToggle) {
                                  formik.setFieldValue("factory_gem.bezel", "");
                                }
                                setDefaultFactoryBezelToggle(
                                  !defaultFactoryBezelToggle
                                );
                              }}
                              checked={defaultFactoryBezelToggle}
                            />
                            <label
                              class="form-check-label bg-unset1"
                              for="flexCheckDefault"
                            >
                              Bezel
                            </label>
                          </div>
                        </div>
                        <div className="col-md-9">
                          <div className="form-check-label-Indicate">
                            {defaultFactoryBezelToggle && (
                              <>
                                <label
                                  className="form-check-label"
                                  htmlFor="flexRadioDefault1"
                                >
                                  Indicate what's customised
                                </label>
                                <input
                                  type="text"
                                  name="factory_gem.bezel"
                                  onChange={formik.handleChange}
                                  value={formik.values.factory_gem.bezel}
                                  className="form-control"
                                />
                              </>
                            )}
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckDefault"
                              onChange={(event) => {
                                if (defaultFactoryLugsToggle) {
                                  formik.setFieldValue("factory_gem.lugs", "");
                                }
                                setDefaultFactoryLugsToggle(
                                  !defaultFactoryLugsToggle
                                );
                              }}
                              checked={defaultFactoryLugsToggle}
                            />
                            <label
                              class="form-check-label bg-unset1"
                              for="flexCheckDefault"
                            >
                              Lugs
                            </label>
                          </div>
                        </div>
                        <div className="col-md-9">
                          {defaultFactoryLugsToggle && (
                            <>
                              <label
                                className="form-check-label-Indicate"
                                htmlFor="flexRadioDefault1"
                              >
                                Indicate what's Gem-setted
                              </label>
                              <input
                                type="text"
                                name="factory_gem.lugs"
                                onChange={formik.handleChange}
                                value={formik.values.factory_gem.lugs}
                                className="form-control"
                              />
                            </>
                          )}
                        </div>
                        <div className="col-md-3">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckDefault"
                              onChange={(event) => {
                                if (defaultFactoryLugsToggle) {
                                  formik.setFieldValue("factory_gem.clasp", "");
                                }
                                setDefaultFactoryClaspToggle(
                                  !defaultFactoryClaspToggle
                                );
                              }}
                              checked={defaultFactoryClaspToggle}
                            />
                            <label
                              class="form-check-label bg-unset1"
                              for="flexCheckDefault"
                            >
                              Clasp
                            </label>
                          </div>
                        </div>
                        <div className="col-md-9">
                          {defaultFactoryClaspToggle && (
                            <>
                              <label
                                className="form-check-label-Indicate"
                                htmlFor="flexRadioDefault1"
                              >
                                Indicate what's Gem-setted
                              </label>
                              <input
                                type="text"
                                name="factory_gem.clasp"
                                onChange={formik.handleChange}
                                value={formik.values.factory_gem.clasp}
                                className="form-control"
                              />
                            </>
                          )}
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  <div className="text-danger errChck">
                    {formik.errors?.factory_gem && formik.touched?.factory_gem
                      ? formik.errors?.factory_gem
                      : null}
                  </div>
                  {/* Custom Gem Set */}
                  <div className="col-md-12">
                    <label className="form-label">
                      Custom Gem Set?
                      <span style={{ color: "red", fontSize: 16 }}>*</span>
                    </label>
                    <div className="department">
                      <div className="form-check dis">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="customGemSet"
                          value="Yes"
                          id="yesCustomGemSet"
                          onChange={() => {
                            setDefaultCustomGem("yes");
                            setCustomGemSet(true);
                            formik.setFieldValue("custom_gem_set", "Yes");
                          }}
                          checked={defaultCustomGem === "yes"}
                          // defaultChecked={formik.values.dept === "sales"}
                          // onClick={(e) => radioHandler(1)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="yesCustomGemSet"
                        >
                          Yes
                        </label>
                      </div>

                      <div className="form-check dis">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="customGemSet"
                          value="No"
                          id="noCustomGemSet"
                          onChange={() => {
                            setDefaultCustomGem("no");
                            setCustomGemSet(false);
                          }}
                          checked={defaultCustomGem === "no"}
                          // defaultChecked={formik.values.dept === "sales"}
                          // onClick={(e) => radioHandler(1)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="noCustomGemSet"
                        >
                          No
                        </label>
                      </div>
                    </div>
                    {customGemSet ? (
                      <>
                        if yes, tick what's custom
                        <div className="row checklist-row">
                          <div className="col-md-3">
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                value=""
                                id="case"
                                onChange={(event) => {
                                  if (defaultCustomCaseToggle) {
                                    formik.setFieldValue("custom_gem.case", "");
                                  }
                                  setDefaultCustomCaseToggle(
                                    !defaultCustomCaseToggle
                                  );
                                }}
                                checked={defaultCustomCaseToggle}
                              />
                              <label
                                class="form-check-label bg-unset1"
                                for="case"
                              >
                                Case
                              </label>
                            </div>
                          </div>
                          <div className="col-md-9">
                            {defaultCustomCaseToggle && (
                              <>
                                <label className="form-check-label-Indicate">
                                  Indicate what's Gem-setted
                                </label>
                                <input
                                  type="text"
                                  name="custom_gem.case"
                                  onChange={formik.handleChange}
                                  value={formik.values.custom_gem.case}
                                  className="form-control"
                                />
                              </>
                            )}
                          </div>
                          <div className="col-md-3">
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                value=""
                                id="dial"
                                onChange={(event) => {
                                  if (defaultCustomBraceletToggle) {
                                    formik.setFieldValue("custom_gem.dial", "");
                                  }
                                  setDefaultCustomDialToggle(
                                    !defaultCustomDialToggle
                                  );

                                  // if (defaultCustomDialToggle) {

                                  //     setDefaultCustomGemDial("")
                                  // }
                                }}
                                checked={defaultCustomDialToggle}
                              />
                              <label
                                class="form-check-label bg-unset1"
                                for="dial"
                              >
                                Dial
                              </label>
                            </div>
                          </div>
                          <div className="col-md-9">
                            {defaultCustomDialToggle && (
                              <>
                                <label className="form-check-label-Indicate">
                                  Indicate what's Gem-setted
                                </label>
                                <input
                                  type="text"
                                  name="custom_gem.dial"
                                  onChange={formik.handleChange}
                                  value={formik.values.custom_gem.dial}
                                  className="form-control"
                                />
                              </>
                            )}
                          </div>
                          <div className="col-md-3">
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                value=""
                                id="bracelet"
                                onChange={(event) => {
                                  if (defaultCustomBraceletToggle) {
                                    formik.setFieldValue(
                                      "custom_gem.bracelet",
                                      ""
                                    );
                                  }
                                  setDefaultCustomBraceletToggle(
                                    !defaultCustomBraceletToggle
                                  );
                                }}
                                checked={defaultCustomBraceletToggle}
                              />
                              <label
                                class="form-check-label bg-unset1"
                                for="bracelet"
                              >
                                Bracelet
                              </label>
                            </div>
                          </div>
                          <div className="col-md-9">
                            {defaultCustomBraceletToggle && (
                              <>
                                <label className="form-check-label-Indicate">
                                  Indicate what's Gem-setted
                                </label>
                                <input
                                  type="text"
                                  name="custom_gem.bracelet"
                                  onChange={formik.handleChange}
                                  value={formik.values.custom_gem.bracelet}
                                  className="form-control"
                                />
                              </>
                            )}
                          </div>
                          <div className="col-md-3">
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                value=""
                                id="bezel"
                                onChange={(event) => {
                                  if (defaultCustomBezelToggle) {
                                    formik.setFieldValue(
                                      "custom_gem.bezel",
                                      ""
                                    );
                                  }
                                  setDefaultCustomBezelToggle(
                                    !defaultCustomBezelToggle
                                  );
                                }}
                                checked={defaultCustomBezelToggle}
                              />
                              <label
                                class="form-check-label bg-unset1"
                                for="bezel"
                              >
                                Bezel
                              </label>
                            </div>
                          </div>
                          <div className="col-md-9">
                            {defaultCustomBezelToggle && (
                              <>
                                <label className="form-check-label-Indicate">
                                  Indicate what's Gem-setted
                                </label>
                                <input
                                  type="text"
                                  name="custom_gem.bezel"
                                  onChange={formik.handleChange}
                                  value={formik.values.custom_gem.bezel}
                                  className="form-control"
                                />
                              </>
                            )}
                          </div>
                          <div className="col-md-3">
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                value=""
                                id="lugs"
                                onChange={(event) => {
                                  if (defaultCustomLugsToggle) {
                                    formik.setFieldValue("custom_gem.lugs", "");
                                  }
                                  setDefaultCustomLugsToggle(
                                    !defaultCustomLugsToggle
                                  );
                                }}
                                checked={defaultCustomLugsToggle}
                              />
                              <label
                                class="form-check-label bg-unset1"
                                for="lugs"
                              >
                                Lugs
                              </label>
                            </div>
                          </div>
                          <div className="col-md-9">
                            {defaultCustomLugsToggle && (
                              <>
                                <label className="form-check-label-Indicate">
                                  Indicate what's Gem-setted
                                </label>
                                <input
                                  type="text"
                                  name="custom_gem.lugs"
                                  onChange={formik.handleChange}
                                  value={formik.values.custom_gem.lugs}
                                  className="form-control"
                                />
                              </>
                            )}
                          </div>
                          <div className="col-md-3">
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                value=""
                                id="clasp"
                                onChange={(event) => {
                                  if (defaultCustomClaspToggle) {
                                    formik.setFieldValue(
                                      "custom_gem.clasp",
                                      ""
                                    );
                                  }
                                  setDefaultCustomClaspToggle(
                                    !defaultCustomClaspToggle
                                  );
                                }}
                                checked={defaultCustomClaspToggle}
                              />
                              <label
                                class="form-check-label bg-unset1"
                                for="clasp"
                              >
                                Clasp
                              </label>
                            </div>
                          </div>
                          <div className="col-md-9">
                            <div className="form-check-label-Indicate">
                              {defaultCustomClaspToggle && (
                                <>
                                  <label className="form-check-label">
                                    Indicate what's customised
                                  </label>
                                  <input
                                    type="text"
                                    name="custom_gem.clasp"
                                    onChange={formik.handleChange}
                                    value={formik.values.custom_gem.clasp}
                                    className="form-control"
                                  />
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    <div className="text-danger errChck">
                      {formik.errors.custom_gem && formik.touched.custom_gem
                        ? formik.errors.custom_gem
                        : null}
                    </div>
                  </div>

                  {defaultLocation && (
                    <div className="col-md-12">
                      <label htmlFor="locationDefault" className="form-label">
                        Location of this product?{" "}
                        <span style={{ color: "red", fontSize: 16 }}>*</span>
                      </label>
                      <input
                        name="locationDefault"
                        type="text"
                        className="form-control"
                        readOnly={true}
                        value={defaultLocation}
                      />
                    </div>
                  )}
                  <div className="col-md-12">
                    <label htmlFor="location" className="form-label mt-3">
                      Add new location of this product?
                    </label>
                    <input
                      name="location"
                      type="text"
                      className="form-control"
                      ref={inputRef}
                      autoComplete="off"
                      placeholder="Enter location of this product"
                    />
                    <div className="text-danger">
                      {formik.errors.location && formik.touched.location
                        ? formik.errors.location
                        : null}
                    </div>
                  </div>

                  <div className="container_watch mt-3">
                    <div className="row">
                      <div className="col-12">
                        <div className="uploads_images">
                          <h5>
                            Upload watch images <span className="start">*</span>
                          </h5>
                          <p>Please upload Image of max 10mb</p>
                        </div>
                        <div className="">
                          <div className="row">
                            <div className="col-md-4">
                              <div className="watch_box">
                                {/* <img src={img} alt="" /> */}
                                <div className="upload-btn-wrapper">
                                  <button className="btn">+</button>
                                  <input
                                    type="file"
                                    name="myfile"
                                    onChange={(event) => {
                                      setdefaultThumbnailUrl(null);
                                      setVideoUrl(null);
                                      setImagesIfImg(null);
                                      let selectedImages = [];
                                      const targetImages = event.target.files;
                                      const targetImagesObject = [
                                        ...targetImages,
                                      ];
                                      targetImagesObject.map((file) => {
                                        if (
                                          file.type === "video/mp4" ||
                                          file.type === "video/ogg"
                                        ) {
                                          const video = videoRef.current;
                                          video.src = URL.createObjectURL(file);
                                          video.onloadedmetadata = () => {
                                            video.currentTime = 5; // Capture the frame at 5 seconds
                                            video.onseeked = generateThumbnail;
                                          };
                                          return (selectedImages = null);
                                        }
                                        setThumbnailUrl(null);
                                        return selectedImages.push(
                                          URL.createObjectURL(file)
                                        );
                                      });
                                      setImagesPrimary(selectedImages);
                                      formik.setFieldValue(
                                        "thumb_image",
                                        event.target.files[0]
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="preview_box">
                                <div className="">
                                  {defaultThumbnailUrl && (
                                    // <img src={defaultThumbnailUrl} alt="Thumbnail" />
                                    <span
                                      className="material-icons"
                                      title="Movie"
                                    >
                                      movie
                                    </span>
                                  )}

                                  <video
                                    ref={videoRefDefault}
                                    style={{ display: "none" }}
                                    controls
                                  >
                                    <source src={videoUrl} type="video/mp4" />
                                  </video>

                                  <canvas
                                    ref={canvasRefDefault}
                                    style={{ display: "none" }}
                                  ></canvas>
                                  <video
                                    ref={videoRef}
                                    controls
                                    style={{ display: "none" }}
                                  ></video>
                                  <canvas
                                    ref={canvasRef}
                                    style={{ display: "none" }}
                                  ></canvas>
                                  {thumbnailUrl && (
                                    <img src={thumbnailUrl} alt="Thumbnail" />
                                  )}
                                  {imagesIf && <img src={imagesIf} alt="" />}
                                  <img src={imagesPrimary} alt="" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="text-danger">
                          {formik.errors.thumb_image &&
                          formik.touched.thumb_image
                            ? formik.errors.thumb_image
                            : null}
                        </div>
                        <div className="watch_txt">
                          <p>Select Secondary images/videos</p>
                        </div>
                      </div>
                    </div>
                    <Images images={images} setImages={setImages} />
                    {/* <video ref={videoSecondaryRef} controls style={{ display: 'none' }}></video>
                                    <canvas ref={canvasSecondaryRef} style={{ display: 'none' }}></canvas> */}
                  </div>

                  <div className="col-12 mt-3">
                    <button
                      type="submit"
                      onClick={(e) => {
                        formik.setFieldValue("product_file", [
                          imageSecondaryTarget,
                          sendDefaultImages,
                        ]);
                        console.log(formik.errors);
                      }}
                      className="btn btn-primary"
                    >
                      {isLoading ? (
                        <>
                          <i className="fa fa-refresh fa-spin"></i>Saving...
                        </>
                      ) : (
                        "Update"
                      )}
                    </button>

                    <Link
                      className="btn btn-primary mx-3"
                      to={
                        localStorage.getItem("user_role") === "sub_admin"
                          ? "/product-management/Modify"
                          : "/product-management"
                      }
                    >
                      Back
                    </Link>
                  </div>
                </div>
                {/* Gem Set */}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ProductEdit;
