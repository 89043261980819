import React from "react";
import { useState, useEffect, useRef } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import axios from "axios";
import LoadingSpinner from "../Loader/LoadingSpinner";
import moment from "moment";
import JoditEditor from "jodit-react";

function NewBulkEmail() {
  const editor = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [inputs, setInputs] = useState({});
  const navigate = useNavigate();
  const params = useLocation();
  useEffect(() => {
    getBrandDataById();
  }, []);

  const config = {
    
    uploader: { insertImageAsBase64URI: true },
    readonly: false,
    toolbarAdaptive: false,
}

  const getBrandDataById = async () => {
    setIsLoading(true);
    await axios
      .get(process.env.REACT_APP_API_KEY + "get-email-template/" + params.state.id, {
        headers: {
          "Content-type": "Application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setIsLoading(false);
        setInputs(res.data.data);
        
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("error>>", error.message);
      });
  };
  
 

  
  

  return (
    <>
      <div className="row">
        <div className="col">
          <div className="page-description">
            <h1>View Email Notification</h1>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <div className="example-container">
            <div className="example-content">
              <form className="row g-3">
                <div className="col-md-6">
                  <label htmlFor="inputEmail4" className="form-label">
                    <strong>Subject Name:</strong>
                  </label><br/>
                  {inputs.subject}
                  
                </div>
                <div className="col-md-6">
                  <label htmlFor="inputEmail4" className="form-label">
                    <strong>Variable Name:</strong>
                  </label><br/>
                  {inputs.variables}
                  
                </div>
                {inputs?.alias === "bulk_email" ? (
                  <div className="col-md-6">
                    <label htmlFor="inputStartDate" className="form-label">
                      <strong>Send Date:</strong>
                    </label>
                    <br/>
                    {moment(inputs.send_date).format(process.env.REACT_APP_DATE_FORMAT)}
                    
                  </div>
                ) : null}
                <div className="col-md-6">
                  <label htmlFor="roles" className="form-label">
                    <strong>Send To:</strong>
                  </label><br/>{inputs.send_to}
                  
                </div>
                <div className="col-12">
                  <JoditEditor
                    ref={editor}
                    config={config}
                    value={inputs.message}
                    tabIndex={1} // tabIndex of textarea
                    
                  />
                </div>
                
                <div className="col-12">
                  
                  <Link
                    className="btn btn-primary mx-3"
                    to={
                      localStorage.getItem("user_role") === "sub_admin"
                        ? "/bulk-email/Modify"
                        : "/bulk-email"
                    }
                  >
                    Back
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NewBulkEmail;
